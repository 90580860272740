<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mareaoperacion-modals
			:modelName="modelName"
		></mareaoperacion-modals>
	</div>
</template>

<script>
import mareaoperacionActions from './MareaoperacionActions';
import mareaoperacionModals from './MareaoperacionModals.vue';

export default {
	name: 'mareaoperacion-grid',
	components: {
		'mareaoperacion-modals': mareaoperacionModals
	},
	data() {
		return {
			modelName: 'mareaoperacion',
			actions: mareaoperacionActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
